const casinoHotels = [
  {
    name: 'Hotel Andersia & Spa, Poznań',
    description: 'Hotel Andersia & Spa, członek Radisson Individuals, to jedno z najlepszych miejsc w Poznaniu dla miłośników luksusu i gier hazardowych. Położony w samym sercu miasta, hotel oferuje łatwy dostęp do historycznych atrakcji oraz nowoczesnych centrów handlowych.',
    image: '/assets/img2.jpg',
    link: 'https://www.andersiahotel.pl/',
    rating: '9.4',
    reviews: '1500',
    price: '300 zł za noc',
    address: 'Plac Andersa 3, 61-894 Poznań, Polska',
    reviewDate: '5 Lip 2024'
  },
  {
    name: 'Sofitel Warsaw Victoria',
    description: 'Sofitel Warsaw Victoria to elegancki hotel z kasynem położony w centrum Warszawy. Znany z luksusowych pokoi i najwyższej klasy udogodnień, takich jak centrum spa, basen i wykwintne restauracje.',
    image: '/assets/img6.jpg',
    link: 'https://www.sofitel-warsaw-victoria.com/',
    rating: '9.2',
    reviews: '2000',
    price: '280 zł za noc',
    address: 'Krakowskie Przedmieście 11, 00-071 Warszawa, Polska',
    reviewDate: '5 Lip 2024'
  },
  {
    name: 'Novotel Katowice Centrum',
    description: 'Novotel Katowice Centrum to nowoczesny hotel z kasynem w sercu Śląska. Hotel oferuje komfortowe pokoje, centrum fitness, basen i znakomite restauracje.',
    image: '/assets/img7.jpg',
    link: 'https://www.novotel.com/katowice/',
    rating: '8.8',
    reviews: '1200',
    price: '250 zł za noc',
    address: 'al. Roździeńskiego 16, 40-202 Katowice, Polska',
    reviewDate: '5 Lip 2024'
  },
  {
    name: 'Hilton Gdańsk',
    description: 'Hilton Gdańsk to luksusowy hotel położony nad Motławą, oferujący nie tylko piękne widoki, ale również kasyno na miejscu. Goście mogą korzystać z eleganckich pokoi, basenu na dachu oraz wyśmienitych restauracji.',
    image: '/assets/img8.jpg',
    link: 'https://www.hilton.com/gan/gdansk/',
    rating: '9.0',
    reviews: '1100',
    price: '270 zł za noc',
    address: 'Targ Rybny 1, 80-838 Gdańsk, Polska',
    reviewDate: '5 Lip 2024'
  },
  {
    name: 'Hotel Marriott, Warszawa',
    description: 'Hotel Marriott w Warszawie to ikona luksusu z kasynem na miejscu. Położony w samym centrum miasta, hotel oferuje niezrównane widoki na panoramę Warszawy.',
    image: '/assets/img10.jpg',
    link: 'https://www.marriott.com/warsaw/',
    rating: '9.1',
    reviews: '1600',
    price: '290 zł za noc',
    address: 'Al. Jerozolimskie 65/79, 00-697 Warszawa, Polska',
    reviewDate: '5 Lip 2024'
  },
  {
    name: 'Casino Hotel Grand, Wrocław',
    description: 'Casino Hotel Grand we Wrocławiu to idealne miejsce dla miłośników gier hazardowych i luksusu. Oferuje eleganckie pokoje, znakomite restauracje oraz ekscytujące kasyno.',
    image: '/assets/img11.webp',
    link: 'https://www.casinohotelgrand.com/',
    rating: '8.7',
    reviews: '900',
    price: '220 zł za noc',
    address: 'ul. Piłsudskiego 88, 50-017 Wrocław, Polska',
    reviewDate: '5 Lip 2024'
  }
];

export default casinoHotels;
