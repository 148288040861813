
import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import { Button } from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
      <Header />
        <section className="text-center my-8">
        <img className="w-full max-h-[450px] object-cover mt-5 mb-10 object-bottom" src="/assets/img14.webp" alt="hotels" />
        <div className="container mx-auto px-4 my-10">
          <h1 className="text-4xl font-bold">Najlepsze Hotele Kasynowe w Polsce</h1>
          <p className="mt-4 text-lg">
            Odkryj ekskluzywną selekcję najlepszych hoteli kasynowych w Polsce, gdzie luksus, rozrywka i najwyższej klasy udogodnienia łączą się, aby zapewnić niezapomniane doświadczenia.
          </p>
          
          <table className="w-full text-left table-auto border-collapse bg-slate-50 rounded my-10 shadow-md">
            <thead className='bg-slate-200'>
              <tr>
                <th className="p-4">Nazwa Hotelu</th>
                <th className="p-4">Ocena Zakwaterowania</th>
                <th className="p-4">Cena, zł</th>
                <th className="p-4">Dostępność</th>
              </tr>
            </thead>
            <tbody>
              {casinoHotels.map((hotel, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2">{hotel.name}</td>
                  <td className="px-4 py-2">{hotel.rating} <svg className="inline-block" xmlns="http://www.w3.org/2000/svg" height="14px" width="14px" version="1.1" id="Capa_1" viewBox="0 0 47.94 47.94" >
<path fill="#ED8A19" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757  c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042  c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685  c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528  c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956  C22.602,0.567,25.338,0.567,26.285,2.486z"/>
</svg></td>
                  <td className="px-4 py-2">{hotel.price}</td>
                  <td className="px-4 py-2">
                    <a href={hotel.link} className="text-primary underline">Sprawdź Dostępność</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button as="a" href="/hotels" className="font-bold rounded bg-primary py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[hover]:data-[active]:bg-sky-700 transition-all ease-in-out duration-150">
            Zobacz Więcej
          </Button>
        </div>
        </section>
        
        <div className="container mx-auto px-4 mt-24 mb-20">
        <section className="my-8">
          {infoBlocks.map((block, index) => (
            <div key={index} className="flex flex-col lg:flex-row items-center my-20 md:my-32">
              {index % 2 === 0 ? (
                <>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-3xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-700 text-md">{block.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-3xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-700 text-md">{block.description}</p>
                  </div>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                </>
              )}
            </div>
          ))}
        </section>

        <section className="my-8">
          <div className="flex flex-col lg:flex-row items-center my-20 md:my-32">
            <img src="/assets/img9.jpg" alt="Doświadczenie w Kasynie" className="w-full lg:w-1/2 h-80 object-cover" />
            <div className="p-6 lg:w-1/2">
              <h3 className="text-2xl font-semibold mb-4">Co robić w Hotelach Kasynowych</h3>
              <p className="text-gray-600">
                Hotele kasynowe oferują mnóstwo atrakcji poza samym kasynem. Goście mogą cieszyć się restauracjami światowej klasy, relaksować się w luksusowych spa lub korzystać z rozległych basenów. Wiele hoteli kasynowych oferuje także rozrywkę na żywo, od koncertów muzycznych po przedstawienia teatralne, co gwarantuje, że nigdy nie będzie nudno. Dla tych, którzy lubią być aktywni, dostępne są centra fitness i obiekty sportowe. Dodatkowo, wysokiej klasy butiki oraz tętniące życiem opcje nocnej rozrywki czynią hotele kasynowe kompletnym miejscem rozrywki.
              </p>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center my-20">
            <div className="p-6 lg:w-1/2">
              <h3 className="text-2xl font-semibold mb-4">Korzyści z Pobytu w Hotelach Kasynowych</h3>
              <p className="text-gray-600">
                Pobyt w hotelu kasynowym wiąże się z licznymi korzyściami. Te obiekty oferują unikalne połączenie luksusowych zakwaterowań i ekscytujących opcji rozrywki. Goście mogą cieszyć się wygodą posiadania najwyższej jakości restauracji, barów i rozrywki na żywo, wszystko w ramach jednego obiektu. Obiekty do gier są światowej klasy, oferując szeroką gamę opcji zarówno dla początkujących, jak i doświadczonych graczy. Dodatkowo, ogólna atmosfera hoteli kasynowych jest żywa i dynamiczna, co czyni je idealnym miejscem na energiczny i pamiętny pobyt. Wiele hoteli kasynowych oferuje również ekskluzywne usługi VIP i pakiety, zapewniając gościom premium doświadczenie.
              </p>
            </div>
            <img src="/assets/img7.jpg" alt="Korzyści z Hoteli Kasynowych" className="w-full lg:w-1/2 h-80 object-cover" />
          </div>
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};

const infoBlocks = [
  {
    title: 'Hotel Andersia & Spa, Poznań',
    description: 'Hotel Andersia & Spa, członek Radisson Individuals, to jedno z najlepszych miejsc w Poznaniu dla miłośników luksusu i gier hazardowych. Położony w samym sercu miasta, hotel oferuje łatwy dostęp do historycznych atrakcji oraz nowoczesnych centrów handlowych. Wysoko oceniany za stylowy wystrój i doskonałą obsługę, Andersia & Spa gwarantuje niezapomniane wrażenia.',
    image: '/assets/img4.webp'
  },
  {
    title: 'Sofitel Warsaw Victoria',
    description: 'Sofitel Warsaw Victoria to elegancki hotel z kasynem położony w centrum Warszawy. Znany z luksusowych pokoi i najwyższej klasy udogodnień, takich jak centrum spa, basen i wykwintne restauracje. Kasyno oferuje szeroki wybór gier stołowych i automatów, zapewniając rozrywkę na najwyższym poziomie.',
    image: '/assets/img2.jpg'
  },
  {
    title: 'Novotel Katowice Centrum',
    description: 'Novotel Katowice Centrum to nowoczesny hotel z kasynem w sercu Śląska. Hotel oferuje komfortowe pokoje, centrum fitness, basen i znakomite restauracje. Kasyno na miejscu zapewnia szeroki wybór gier oraz profesjonalną obsługę, co czyni pobyt w Katowicach niezapomnianym.',
    image: '/assets/img5.jpg'
  },
  {
    title: 'Hilton Gdańsk',
    description: 'Hilton Gdańsk to luksusowy hotel położony nad Motławą, oferujący nie tylko piękne widoki, ale również kasyno na miejscu. Goście mogą korzystać z eleganckich pokoi, basenu na dachu oraz wyśmienitych restauracji. Kasyno Hilton Gdańsk oferuje różnorodne gry i turnieje, zapewniając rozrywkę na najwyższym poziomie.',
    image: '/assets/img8.jpg'
  },
  {
    title: 'Hotel Marriott, Warszawa',
    description: 'Hotel Marriott w Warszawie to ikona luksusu z kasynem na miejscu. Położony w samym centrum miasta, hotel oferuje niezrównane widoki na panoramę Warszawy. Goście mogą korzystać z luksusowych pokoi, licznych restauracji i barów oraz centrum fitness. Kasyno Marriott oferuje szeroki wybór gier i atrakcji, które przyciągają zarówno lokalnych, jak i międzynarodowych gości.',
    image: '/assets/img6.jpg'
  }
];

export default HomePage;
