import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import Contacts from './components/Contacts';


const Hotels = () => {
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 mt-24 mb-20">
        <header className="text-center my-8">
          <h1 className="text-4xl font-bold">Top 5 Najwyżej ocenianych hoteli z kasynem</h1>
          <p className="mt-4 text-lg text-gray-700">Odkryj najlepsze hotele z kasynami w Australii, łączące luksusowe zakwaterowanie z najwyższej klasy rozrywką i grami.</p>
        </header>

        <section className="my-8">
          <h2 className="text-3xl font-semibold mb-4">Najwyżej oceniane hotele z kasynem</h2>
          <div className="space-y-8">
            {casinoHotels.map((hotel, index) => (
              <div key={index} className="flex flex-col lg:flex-row bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition duration-300 ease-in-out">
                <img src={hotel.image} alt={hotel.name} className="w-full lg:w-1/3 h-48 lg:h-auto object-cover" />
                <div className="p-6 lg:w-2/3 flex flex-col justify-between">
                  <div>
                    <div className="flex justify-between items-center">
                      <h3 className="text-2xl font-semibold text-gray-900">{hotel.name}</h3>
                      <div className="flex items-center">
                        <span className="bg-secondary text-white rounded-full px-2 py-1 text-sm font-bold mr-2">{hotel.rating}</span>
                        <span className="text-gray-500 text-sm">{hotel.reviews} Opinie</span>
                      </div>
                    </div>
                    <p className="text-gray-600 mt-2">{hotel.description}</p>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <div className="text-primary font-bold">Od {hotel.price}</div>
                    <a href={hotel.link} className="bg-primary text-white py-2 px-4 rounded hover:bg-secondary transition-colors">Sprawdź dostępność</a>
                  </div>
                  <div className="mt-4 text-gray-500 text-sm">
                    <p>Adres: {hotel.address}</p>
                    <p>Recenzja w dniu {hotel.reviewDate}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};


export default Hotels;
